<footer class="footer-area footer-bg2 pt-100">
    <div class="container">
        <div class="footer-midal pb-70">
            <div class="row">
                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <div class="footer-img">
                            <img src="assets/img/logo/logo2.png" alt="Logo Belakanglayar Solutions">
                        </div>
                        <p>Belakanglayar Solutions adalah penyedia jasa pembuatan website dan sistem informasi profesional di Yogyakarta. Kami berkomitmen menghadirkan solusi digital terbaik untuk mengembangkan bisnis Anda.</p>
                        <div class="footer-social-icon">
                            <ul class="social-link">
                                <li><a href="https://facebook.com/belakanglayar" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="https://instagram.com/belakanglayar" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                                <li><a href="https://youtube.com/belakanglayar" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-5">
                    <div class="footer-widget">
                        <h3>Layanan</h3>
                        <ul class="footer-list">
                            <li><a routerLink="/services">Website Development</a></li>
                            <li><a routerLink="/services">Sistem Informasi</a></li>
                            <li><a routerLink="/services">E-Commerce</a></li>
                            <li><a routerLink="/services">Aplikasi Custom</a></li>
                            <li><a routerLink="/services">Maintenance</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget pl-5">
                        <h3>Perusahaan</h3>
                        <ul class="footer-list">
                            <li><a routerLink="/">Beranda</a></li>
                            <li><a routerLink="/about">Tentang Kami</a></li>
                            <li><a routerLink="/portfolio">Portfolio</a></li>
                            <li><a routerLink="/blog">Blog</a></li>
                            <li><a routerLink="/contact">Kontak</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Kontak</h3>
                        <ul class="footer-list-two">
                            <li><i class='flaticon-telephone'></i><a href="tel:+6282299748978">+62 822-9974-8978</a></li>
                            <li><i class='flaticon-email-1'></i><a href="mailto:hello&#64;belakanglayar.com">pipinibnu&#64;gmail.com</a></li>
                            <li><i class='flaticon-place'></i>Jl. Sukun No.22, Ngringin, Condongcatur, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta 55281</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right-area">
            <div class="row">
                <div class="col-lg-8">
                    <div class="copy-right-text text-start">
                        <p>© 2024 Belakanglayar. All Rights Reserved.</p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="copy-right-list">
                        <ul>
                            <li><a routerLink="/privacy-policy">Kebijakan Privasi</a></li>
                            <li><a routerLink="/terms-conditions">Syarat & Ketentuan</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>