<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-widget-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Layanan Kami</span>
            <h2 class="h2-color">Solusi Digital untuk <b>Transformasi Bisnis Anda</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let service of getCurrentPageServices()">
                <div class="services-card">
                    <a [routerLink]="['/services-details', service.id]">
                        <img [src]="service.icon" [alt]="service.name">
                    </a>
                    <h3><a [routerLink]="['/services-details', service.id]">{{service.name}}</a></h3>
                    <p>{{service.description}}</p>
                    <span class="brand-label">{{service.brand}}</span>
                    <div class="services-card-shape">
                        <img src="assets/img/service/service-shape.png" alt="Shape">
                    </div>
                </div>
            </div>
        </div>

        <!-- Pagination -->
        <div class="col-lg-12 col-md-12">
            <div class="pagination-area text-center">
                <a href="javascript:void(0)" class="prev page-numbers" 
                   [class.disabled]="currentPage === 1"
                   (click)="changePage(currentPage - 1)">
                    <i class="bx bx-chevron-left"></i>
                </a>
                
                <ng-container *ngFor="let page of getPageNumbers()">
                    <span class="page-numbers" 
                          [class.current]="currentPage === page"
                          (click)="changePage(page)">
                        {{page}}
                    </span>
                </ng-container>

                <a href="javascript:void(0)" class="next page-numbers"
                   [class.disabled]="currentPage === getTotalPages()"
                   (click)="changePage(currentPage + 1)">
                    <i class="bx bx-chevron-right"></i>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>