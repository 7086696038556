<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Privacy Policy</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Privacy Policy</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>
<div class="terms-conditions-area pt-50 pb-70">
    <div class="container">
        <div class="single-content">
            <h2>Kebijakan Privasi Belakanglayar Solutions</h2>
            <p>Selamat datang di Kebijakan Privasi Belakanglayar Solutions. Kami berkomitmen untuk melindungi dan menghormati privasi Anda. Kebijakan ini menjelaskan bagaimana kami mengumpul, menggunakan, dan melindungi informasi yang Anda berikan.</p>

            <h3>1. Informasi yang Kami Kumpulkan</h3>
            <p>Kami mengumpulkan informasi berikut ketika Anda menggunakan layanan kami:</p>
            <ul>
                <li>Informasi kontak (nama, email, nomor telepon)</li>
                <li>Informasi perusahaan (nama bisnis, alamat)</li>
                <li>Data teknis website Anda</li>
                <li>Informasi penggunaan layanan</li>
            </ul>

            <h3>2. Penggunaan Informasi</h3>
            <p>Kami menggunakan informasi yang dikumpulkan untuk:</p>
            <ul>
                <li>Menyediakan dan mengelola layanan yang Anda minta</li>
                <li>Berkomunikasi tentang proyek dan layanan</li>
                <li>Meningkatkan layanan kami</li>
                <li>Mengirim update dan informasi penting</li>
            </ul>

            <h3>3. Keamanan Data</h3>
            <p>Kami menerapkan langkah-langkah keamanan berikut:</p>
            <ul>
                <li>Enkripsi data sensitif</li>
                <li>Akses terbatas ke informasi pribadi</li>
                <li>Monitoring keamanan 24/7</li>
                <li>Backup data berkala</li>
            </ul>

            <h3>4. Pembagian Informasi</h3>
            <p>Kami tidak akan membagi informasi Anda dengan pihak ketiga kecuali:</p>
            <ul>
                <li>Dengan persetujuan tertulis dari Anda</li>
                <li>Diperlukan untuk menyelesaikan proyek</li>
                <li>Diwajibkan oleh hukum</li>
            </ul>

            <h3>5. Cookies dan Teknologi Pelacakan</h3>
            <p>Website kami menggunakan cookies untuk:</p>
            <ul>
                <li>Meningkatkan pengalaman pengguna</li>
                <li>Menganalisis penggunaan website</li>
                <li>Menyesuaikan konten yang relevan</li>
            </ul>

            <h3>6. Hak Pengguna</h3>
            <p>Anda memiliki hak untuk:</p>
            <ul>
                <li>Mengakses informasi pribadi Anda</li>
                <li>Memperbarui atau mengoreksi data</li>
                <li>Meminta penghapusan data</li>
                <li>Membatasi penggunaan data</li>
            </ul>

            <h3>7. Perubahan Kebijakan</h3>
            <p>Kami dapat memperbarui kebijakan ini sewaktu-waktu. Perubahan akan diumumkan di website kami dan, jika signifikan, akan diberitahukan melalui email.</p>

            <h3>8. Kontak</h3>
            <p>Untuk pertanyaan tentang kebijakan privasi ini, silakan hubungi:</p>
            <ul>
                <li>Email: pipinibnu&#64;gmail.com</li>
                <li>Telepon: +62 8229-974-8978</li>
                <li>Alamat: Jl. Sukun No.22, Ngringin, Condongcatur, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta</li>
            </ul>

            <div class="update-info mt-4">
                <p><strong>Terakhir diperbarui:</strong> Maret 2025</p>
            </div>
        </div>
    </div>
</div>