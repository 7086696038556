import { Component, OnInit } from '@angular/core';

interface Service {
  id: number;
  name: string;
  brand: string;
  description: string;
  icon: string;
  features: string[];
}

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 6;
  services: Service[] = [
    {
      id: 1,
      name: 'Pembuatan Website',
      brand: 'Belakanglayar IT Solutions',
      description: 'Website profesional dengan desain modern, responsif, dan SEO-friendly untuk meningkatkan presence bisnis Anda.',
      icon: 'assets/img/services/website-dev-icon.png',
      features: ['Custom Design', 'Responsive', 'SEO Friendly', 'CMS Admin', 'Optimasi Performa']
    },
    {
      id: 2,
      name: 'Aplikasi Mobile',
      brand: 'Techno Digital Consulting',
      description: 'Pengembangan aplikasi Android & iOS yang inovatif untuk memperluas jangkauan bisnis Anda.',
      icon: 'assets/img/services/mobile-app-icon.png',
      features: ['Android & iOS', 'UI/UX Modern', 'Push Notification', 'API Integration', 'Offline Mode']
    },
    {
      id: 3,
      name: 'Sistem Informasi Perusahaan',
      brand: 'Vision IT Consultancy',
      description: 'Solusi ERP, CRM, dan HRMS terintegrasi untuk mengoptimalkan proses bisnis perusahaan.',
      icon: 'assets/img/services/erp-system-icon.png',
      features: ['ERP System', 'CRM', 'HRMS', 'Inventory', 'Accounting']
    },
    {
      id: 4,
      name: 'Keamanan IT',
      brand: 'CyberSecure Consulting',
      description: 'Layanan keamanan IT komprehensif untuk melindungi aset digital bisnis Anda.',
      icon: 'assets/img/services/security-icon.png',
      features: ['Security Audit', 'Penetration Test', '24/7 Monitoring', 'Data Encryption', 'Backup']
    },
    {
      id: 5,
      name: 'Cloud Computing',
      brand: 'Belakanglayar IT Solutions',
      description: 'Solusi cloud yang scalable untuk mengoptimalkan infrastruktur IT bisnis.',
      icon: 'assets/img/services/cloud-icon.png',
      features: ['Cloud Server', 'Backup', 'Scaling', 'Monitoring', 'Optimization']
    },
    {
      id: 6,
      name: 'Business Intelligence',
      brand: 'Vision IT Consultancy',
      description: 'Analisis data bisnis untuk pengambilan keputusan yang lebih baik.',
      icon: 'assets/img/services/bi-icon.png',
      features: ['Data Analytics', 'Reporting', 'Dashboard', 'Forecasting', 'KPI Tracking']
    },
    {
      id: 7,
      name: 'IoT Solutions',
      brand: 'Techno Digital Consulting',
      description: 'Solusi Internet of Things untuk otomatisasi dan monitoring bisnis.',
      icon: 'assets/img/services/iot-icon.png',
      features: ['Sensors', 'Automation', 'Monitoring', 'Integration', 'Analytics']
    },
    {
      id: 8,
      name: 'Digital Transformation',
      brand: 'Belakanglayar IT Solutions',
      description: 'Konsultasi dan implementasi transformasi digital untuk bisnis Anda.',
      icon: 'assets/img/services/digital-transform-icon.png',
      features: ['Strategy', 'Implementation', 'Training', 'Support', 'Optimization']
    },
    {
      id: 9,
      name: 'IT Support',
      brand: 'CyberSecure Consulting',
      description: 'Layanan dukungan teknis 24/7 untuk sistem IT perusahaan.',
      icon: 'assets/img/services/support-icon.png',
      features: ['24/7 Support', 'Maintenance', 'Monitoring', 'Training', 'Documentation']
    }
  ];

  constructor() { }

  ngOnInit(): void { }

  getCurrentPageServices(): Service[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.services.slice(startIndex, startIndex + this.itemsPerPage);
  }

  getTotalPages(): number {
    return Math.ceil(this.services.length / this.itemsPerPage);
  }

  getPageNumbers(): number[] {
    return Array(this.getTotalPages()).fill(0).map((_, i) => i + 1);
  }

  changePage(page: number): void {
    if (page >= 1 && page <= this.getTotalPages()) {
      this.currentPage = page;
      window.scrollTo(0, 0);
    }
  }
}
