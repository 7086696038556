<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>FAQ</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>FAQ</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="faq-area pt-50 pb-70">
    <div class="row pt-45">
        <div class="col-lg-6 col-md-12">
            <div class="faq-accordion">
                <ul class="accordion">
                    <li class="accordion-item">
                        <a class="accordion-title" href="javascript:void(0)">
                            <i class='bx bx-chevron-down'></i> Berapa biaya pembuatan website profesional?
                        </a>
                        <div class="accordion-content">
                            <p>Biaya pembuatan website bervariasi mulai dari 5 juta rupiah, tergantung pada kompleksitas, fitur, dan kebutuhan spesifik proyek Anda. Kami menawarkan konsultasi gratis untuk membantu menentukan solusi terbaik sesuai budget.</p>
                        </div>
                    </li>
    
                    <li class="accordion-item">
                        <a class="accordion-title" href="javascript:void(0)">
                            <i class='bx bx-chevron-down'></i> Berapa lama waktu pengembangan website?
                        </a>
                        <div class="accordion-content">
                            <p>Waktu pengembangan website berkisar 2-8 minggu, tergantung pada kompleksitas proyek. Website company profile sederhana bisa selesai dalam 2-3 minggu, sedangkan sistem yang lebih kompleks seperti e-commerce atau sistem informasi membutuhkan waktu 4-8 minggu.</p>
                        </div>
                    </li>
    
                    <li class="accordion-item">
                        <a class="accordion-title" href="javascript:void(0)">
                            <i class='bx bx-chevron-down'></i> Apakah website yang dibuat responsive?
                        </a>
                        <div class="accordion-content">
                            <p>Ya, semua website yang kami kembangkan menggunakan desain responsive yang menyesuaikan dengan berbagai ukuran layar (desktop, tablet, mobile). Kami juga memastikan performa optimal dan kecepatan loading di semua perangkat.</p>
                        </div>
                    </li>
    
                    <li class="accordion-item">
                        <a class="accordion-title active" href="javascript:void(0)">
                            <i class='bx bx-chevron-down'></i> Apa saja layanan maintenance yang diberikan?
                        </a>
                        <div class="accordion-content show">
                            <p>Kami memberikan layanan maintenance selama 1 tahun yang mencakup update sistem, backup rutin, monitoring keamanan, dan dukungan teknis. Termasuk juga perbaikan bug dan optimasi performa untuk memastikan website Anda selalu berjalan optimal.</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    
        <div class="col-lg-6 col-md-12">
            <div class="faq-accordion">
                <ul class="accordion">
                    <li class="accordion-item">
                        <a class="accordion-title" href="javascript:void(0)">
                            <i class='bx bx-chevron-down'></i> Bagaimana proses pembayaran?
                        </a>
                        <div class="accordion-content">
                            <p>Kami menerapkan sistem pembayaran bertahap: 50% di awal sebagai uang muka, 25% setelah desain disetujui, dan 25% setelah website selesai dan siap diluncurkan. Pembayaran dapat dilakukan melalui transfer bank atau metode pembayaran lain yang disepakati.</p>
                        </div>
                    </li>
    
                    <li class="accordion-item">
                        <a class="accordion-title" href="javascript:void(0)">
                            <i class='bx bx-chevron-down'></i> Apakah ada garansi untuk website?
                        </a>
                        <div class="accordion-content">
                            <p>Ya, kami memberikan garansi selama 1 tahun untuk perbaikan bug dan masalah teknis. Garansi mencakup perbaikan error sistem, masalah keamanan, dan bantuan teknis. Kami juga menyediakan panduan penggunaan dan pelatihan untuk tim Anda.</p>
                        </div>
                    </li>
    
                    <li class="accordion-item">
                        <a class="accordion-title" href="javascript:void(0)">
                            <i class='bx bx-chevron-down'></i> Bagaimana dengan optimasi SEO?
                        </a>
                        <div class="accordion-content">
                            <p>Setiap website yang kami buat sudah dioptimasi SEO on-page, termasuk struktur kode yang bersih, meta tag yang optimal, dan kecepatan loading yang baik. Kami juga memberikan panduan untuk optimasi konten dan strategi SEO berkelanjutan.</p>
                        </div>
                    </li>
    
                    <li class="accordion-item">
                        <a class="accordion-title active" href="javascript:void(0)">
                            <i class='bx bx-chevron-down'></i> Apakah saya memiliki source code website?
                        </a>
                        <div class="accordion-content show">
                            <p>Ya, setelah pelunasan pembayaran, Anda akan mendapatkan hak penuh atas source code website beserta dokumentasinya. Kami juga memberikan panduan pengelolaan dan akses ke semua aset digital yang terkait dengan website Anda.</p>
                        </div>
                    </li>
                </ul>
            </div>   
        </div>
    </div>
</div>

<div class="faq-contact pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Hubungi Kami</span>
            <h2>Ada Pertanyaan? Konsultasikan dengan Kami</h2>
        </div>
    
        <div class="row pt-45 align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="faq-img">
                    <img src="assets/img/faq-img.png" alt="Konsultasi Website dan Sistem Informasi Yogyakarta">
                </div>
            </div>
    
            <div class="col-lg-7 col-md-12">
                <div class="faq-contact-wrap">
                    <div class="contact-form">
                        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input 
                                            type="text" 
                                            formControlName="name" 
                                            class="form-control" 
                                            placeholder="Nama Anda*"
                                            [ngClass]="{'is-invalid': submitted && f['name'].errors}"
                                        >
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-envelope'></i>
                                        <input 
                                            type="email" 
                                            formControlName="email" 
                                            class="form-control" 
                                            placeholder="Email*"
                                            [ngClass]="{'is-invalid': submitted && f['email'].errors}"
                                        >
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-phone'></i>
                                        <input 
                                            type="text" 
                                            formControlName="phone" 
                                            class="form-control" 
                                            placeholder="No. Telepon*"
                                            [ngClass]="{'is-invalid': submitted && f['phone'].errors}"
                                        >
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-file'></i>
                                        <input 
                                            type="text" 
                                            formControlName="subject" 
                                            class="form-control" 
                                            placeholder="Subjek*"
                                            [ngClass]="{'is-invalid': submitted && f['subject'].errors}"
                                        >
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-message-detail'></i>
                                        <textarea 
                                            formControlName="message" 
                                            class="form-control" 
                                            rows="6" 
                                            placeholder="Pesan Anda*"
                                            [ngClass]="{'is-invalid': submitted && f['message'].errors}"
                                        ></textarea>
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <button 
                                        type="submit" 
                                        class="default-btn border-radius"
                                        [disabled]="loading"
                                    >
                                        {{ loading ? 'Mengirim...' : 'Kirim Pesan' }}
                                        <i class='bx bx-chevron-right'></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>