<app-navbar-style-two></app-navbar-style-two>
<script defer src="https://shown.io/metrics/a68pRbMO02" type="text/javascript"></script>
<div class="banner-area-two">
    <div class="container-fluid">
        <div class="container-max-2">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="banner-content-two">
                        <span>Jasa Web Development Jogja</span>
                        <h1>Solusi <b>Digital</b> Terpercaya untuk Bisnis Anda</h1>
                        <p>Belakanglayar Solutions adalah penyedia jasa pembuatan website dan sistem informasi profesional di Yogyakarta. Kami membantu mengembangkan bisnis Anda dengan solusi digital yang inovatif.</p>
                        <div class="banner-content-btn">
                            <a href="services" class="learn-btn">Lihat Layanan <i class='bx bx-chevron-right'></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="banner-img-2">
                        <img src="assets/img/home-two/home-two-img.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-bottom-shape">
        <div class="shape-one wow fadeInUp" data-wow-delay=".3s"><img src="assets/img/home-two/home-two-shape1.png" alt="Images"></div>
        <div class="shape-two wow fadeInUp" data-wow-delay=".5s"><img src="assets/img/home-two/home-two-shape2.png" alt="Images"></div>
        <div class="shape-three wow fadeInUp" data-wow-delay=".7s"><img src="assets/img/home-two/home-two-shape3.png" alt="Images"></div>
        <div class="banner-dots1"><img src="assets/img/home-two/home-two-dots.png" alt="Images"></div>
        <div class="banner-dots2"><img src="assets/img/home-two/home-two-dots2.png" alt="Images"></div>
    </div>
</div>

<div class="service-area-two">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Layanan Kami</span>
            <h2>Solusi Digital Terbaik untuk Mengembangkan Bisnis Anda</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <h3><a routerLink="/services-details">Pembuatan Website Profesional</a></h3>
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img src="assets/img/service/service-icon7.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                    <p>Kami menyediakan layanan pembuatan website dengan desain modern dan responsif yang memastikan tampilan optimal di berbagai perangkat. Setiap website yang kami buat dibangun dengan teknik SEO terbaik, agar bisnis Anda lebih mudah ditemukan oleh pelanggan potensial di mesin pencari seperti Google.</p>
                    <a routerLink="/services-details" class="learn-btn">Selengkapnya <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <h3><a routerLink="/services-details">Solusi Sistem Informasi Kustom</a></h3>
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img src="assets/img/service/service-icon8.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                    <p>Dapatkan solusi sistem informasi yang dirancang untuk menyederhanakan dan mengotomatisasi proses bisnis Anda. Kami menawarkan berbagai solusi mulai dari ERP, CRM, hingga sistem manajemen kustom yang dapat disesuaikan dengan kebutuhan dan tujuan bisnis Anda.</p>
                    <a routerLink="/services-details" class="learn-btn">Selengkapnya <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="services-item">
                    <h3><a routerLink="/services-details">Aplikasi Bisnis yang Efektif</a></h3>
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img src="assets/img/service/service-icon9.png" alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                    <p>Kami memahami pentingnya aplikasi yang mudah digunakan dan dapat mengoptimalkan operasional bisnis Anda. Dengan pendekatan yang user-friendly, kami merancang aplikasi bisnis custom yang sesuai dengan kebutuhan spesifik perusahaan Anda, baik untuk kebutuhan internal maupun customer-facing.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="service-two-shape">
        <div class="shape-in1"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape-in2"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape-in3"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape-in4"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape-in5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
    </div>
</div>

<div class="about-area-two pb-70">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img-2">
                        <img src="assets/img/about/about-img2.png" alt="Tim Belakanglayar Solutions Yogyakarta">
                    </div>
                </div>
    
                <div class="col-lg-6 col-md-12">
                    <div class="about-content-two">
                        <div class="section-title">
                            <span class="sp-before sp-after">Tentang Kami</span>
                            <h2>Solusi Digital Terpercaya untuk Pertumbuhan Bisnis Anda</h2>
                        </div>
                        <h3>Belakanglayar Solutions - Partner terpercaya dengan pengalaman lebih dari 5 tahun dalam pengembangan website dan sistem informasi di Yogyakarta.</h3>
                        <p>Kami adalah tim profesional yang berkomitmen menghadirkan solusi digital berkualitas untuk membantu bisnis Anda berkembang di era digital. Dengan kombinasi keahlian teknis dan pemahaman bisnis, kami siap mewujudkan visi digital Anda.</p>
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>01</span>
                                    <p>Website Modern</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>02</span>
                                    <p>Sistem Terintegrasi</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>03</span>
                                    <p>Optimasi SEO</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>04</span>
                                    <p>Desain Responsif</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>05</span>
                                    <p>Support 24/7</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>06</span>
                                    <p>Harga Kompetitif</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>5+ Tahun</h3>
                    <p>PENGALAMAN</p>
                </div>
            </div>
    
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>50+</h3>
                    <p>PROYEK SELESAI</p>
                </div>
            </div>
    
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>95%</h3>
                    <p>KLIEN PUAS</p>
                </div>
            </div>
    
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>10+</h3>
                    <p>EXPERT DEVELOPER</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top"><img src="assets/img/counter/counter-shape.png" alt="Images"></div>
</div>

<div class="service-another pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Layanan Unggulan</span>
            <h2>Solusi Digital Profesional untuk Bisnis Anda</h2>
        </div>
    
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details"><i class="flaticon-research service-icon service-icon-bg1"></i></a>
                    <h3><a routerLink="/services-details">Website Perusahaan</a></h3>
                    <p>Pembuatan website perusahaan profesional dengan desain modern, responsif dan SEO friendly. Tingkatkan kredibilitas bisnis Anda di dunia digital.</p>
                    <a routerLink="/services-details" class="learn-btn">Selengkapnya <i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
    
            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details"><i class="flaticon-headphones service-icon service-icon-bg"></i></a>
                    <h3><a routerLink="/services-details">E-Commerce</a></h3>
                    <p>Buat toko online dengan fitur lengkap untuk mengembangkan bisnis Anda. Dilengkapi sistem pembayaran dan inventori yang terintegrasi.</p>
                    <a routerLink="/services-details" class="learn-btn">Selengkapnya <i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
    
            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details"><i class="flaticon-caution service-icon service-icon-bg2"></i></a>
                    <h3><a routerLink="/services-details">Sistem ERP</a></h3>
                    <p>Sistem informasi terintegrasi untuk mengelola seluruh proses bisnis Anda. Dari keuangan, inventori, hingga manajemen pelanggan.</p>
                    <a routerLink="/services-details" class="learn-btn">Selengkapnya <i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
    
            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details"><i class="flaticon-big-data service-icon service-icon-bg3"></i></a>
                    <h3><a routerLink="/services-details">Aplikasi Custom</a></h3>
                    <p>Pengembangan aplikasi sesuai kebutuhan spesifik bisnis Anda. Solusi yang tepat untuk mengoptimalkan proses operasional perusahaan.</p>
                    <a routerLink="/services-details" class="learn-btn">Selengkapnya <i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
    
            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details"><i class="flaticon-money service-icon service-icon-bg4"></i></a>
                    <h3><a routerLink="/services-details">Sistem Manajemen</a></h3>
                    <p>Solusi sistem manajemen untuk sekolah, hotel, klinik dan berbagai jenis usaha. Tingkatkan efisiensi operasional bisnis Anda.</p>
                    <a routerLink="/services-details" class="learn-btn">Selengkapnya <i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
    
            <div class="col-lg-4 col-md-6">
                <div class="services-another-card">
                    <a routerLink="/services-details"><i class="flaticon-share service-icon service-icon-bg5"></i></a>
                    <h3><a routerLink="/services-details">Maintenance & Support</a></h3>
                    <p>Layanan pemeliharaan dan dukungan teknis untuk memastikan sistem Anda berjalan optimal. Termasuk backup, update, dan monitoring 24/7.</p>
                    <a routerLink="/services-details" class="learn-btn">Selengkapnya <i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="service-another-shape">
        <div class="shape-1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape-2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape-3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape-4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape-5"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape-6"><img src="assets/img/shape/shape12.png" alt="Images"></div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Alur Kerja</span>
            <h2 class="h2-color2">Proses Pengembangan yang Terstruktur</h2>
        </div>
    
        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>Analisis Kebutuhan <span>1</span></h3>
                            <p>Mengidentifikasi kebutuhan bisnis Anda secara detail untuk memastikan solusi yang tepat sasaran</p>
                        </li>
                        <li class="text-end">
                            <h3>Perencanaan Proyek <span>2</span></h3>
                            <p>Menyusun rencana pengembangan, timeline, dan resource yang dibutuhkan untuk proyek Anda</p>
                        </li>
                        <li class="text-end">
                            <h3>Desain Interface <span>3</span></h3>
                            <p>Merancang tampilan yang menarik dan user-friendly sesuai dengan brand identity Anda</p>
                        </li>
                    </ul>
                </div>
            </div>
    
            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Proses Pengembangan Website dan Sistem Informasi">
                </div>
            </div>
    
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>4</span>Pengembangan</h3>
                            <p>Membangun sistem dengan teknologi modern dan kode yang terstruktur untuk performa optimal</p>
                        </li>
                        <li>
                            <h3><span>5</span>Pengujian</h3>
                            <p>Testing menyeluruh untuk memastikan sistem berjalan sesuai kebutuhan dan bebas bug</p>
                        </li>
                        <li>
                            <h3><span>6</span>Deploy & Support</h3>
                            <p>Implementasi sistem dan dukungan teknis berkelanjutan untuk operasional yang lancar</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mission-area pt-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="mission-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">Misi Kami</span>
                        <h2 class="h2-color2">Menghadirkan Solusi Digital Terbaik untuk Kesuksesan Bisnis Anda</h2>
                    </div>
                    <p>Belakanglayar Solutions berkomitmen untuk memberikan layanan pengembangan website dan sistem informasi berkualitas tinggi dengan fokus pada inovasi, keamanan, dan kepuasan pelanggan.</p>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list fixed-height">
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>Solusi Inovatif</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list fixed-height">
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>Teknologi Modern</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list fixed-height">
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>Responsif & SEO</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list fixed-height">
                                <i class='bx bx-check'></i>
                                <span>04</span>
                                <p>Keamanan Terjamin</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list fixed-height">
                                <i class='bx bx-check'></i>
                                <span>05</span>
                                <p>Support Premium</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list fixed-height">
                                <i class='bx bx-check'></i>
                                <span>06</span>
                                <p>Harga Bersaing</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-6 col-md-12">
                <div class="mission-img">
                    <img src="assets/img/mission-img.png" alt="Misi Belakanglayar Solutions - Jasa Pembuatan Website & Sistem Informasi Yogyakarta">
                </div>
            </div>
        </div>
    </div>

    <div class="mission-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Blog & Artikel</span>
            <h2 class="h2-color2">Tips & Informasi Seputar Website</h2>
            <p class="margin-auto">Temukan artikel terbaru seputar pengembangan website, sistem informasi, dan strategi digital untuk membantu mengembangkan bisnis Anda</p>
        </div>
    
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog1.png" alt="Pentingnya Website Responsif untuk Bisnis Modern"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>09 April 2024</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">Website Design</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">5 Alasan Mengapa Website Responsif Penting untuk Bisnis Anda</a></h3>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog2.png" alt="Optimasi SEO untuk Website Bisnis"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>10 Juli 2024</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">SEO</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">Panduan Lengkap Optimasi SEO untuk Website Bisnis di Tahun 2024</a></h3>
                    </div>
                </div>
            </div>
    
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog3.png" alt="Sistem Informasi untuk Efisiensi Bisnis"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>19 Juni 2024</li>
                            <li><i class='bx bx-purchase-tag-alt'></i><a routerLink="/blog">Sistem Informasi</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">Manfaat Sistem Informasi Terintegrasi untuk Efisiensi Bisnis</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>

<div class="brand-logo-area">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="data-table-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="data-table-content">
                    <h2>Wujudkan Website Impian Anda Bersama Kami</h2>
                    <a routerLink="/contact" class="default-btn">Mulai Konsultasi <i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
    
            <div class="col-lg-6 col-md-12">
                <div class="data-table-img">
                    <img src="assets/img/data-table-img.png" alt="Konsultasi Pembuatan Website dan Sistem Informasi Yogyakarta">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>