import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  loading = false;
  webhookUrl = 'https://discord.com/api/webhooks/1355466501163061330/U_0ndcgV8oFwxbwQARhJ1ph5_rBCZr1vSXHsE85wsHLggVoCXyIdcTGeHtnWAmlQ2hDu';

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]{10,13}$')]],
      subject: ['', Validators.required],
      message: ['', [Validators.required, Validators.minLength(20)]]
    });
  }

  get f() { return this.contactForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.contactForm.invalid) {
      return;
    }

    this.loading = true;

    // Prepare Discord webhook message
    const webhookData = {
      content: null,
      embeds: [{
        title: '📨 Pesan Baru dari Form Kontak',
        description: `**Subjek:** ${this.f['subject'].value}`,
        color: 3447003, // Discord Blue
        fields: [
          {
            name: '👤 Nama',
            value: this.f['name'].value,
            inline: true
          },
          {
            name: '📧 Email',
            value: this.f['email'].value,
            inline: true
          },
          {
            name: '📱 Telepon',
            value: this.f['phone'].value,
            inline: true
          },
          {
            name: '💬 Pesan',
            value: this.f['message'].value,
            inline: false
          }
        ],
        footer: {
          text: 'Belakanglayar Solutions'
        },
        timestamp: new Date().toISOString()
      }]
    };

    // Set headers for Discord webhook
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // Send to Discord webhook
    this.http.post(this.webhookUrl, webhookData, { headers })
      .subscribe({
        next: () => {
          this.contactForm.reset();
          this.submitted = false;
          this.loading = false;
          alert('Pesan berhasil dikirim! Kami akan segera menghubungi Anda.');
        },
        error: (error) => {
          console.error('Error sending to Discord:', error);
          this.loading = false;
          alert('Maaf, terjadi kesalahan. Silakan coba lagi nanti.');
        }
      });
  }
}