import { Component, OnInit } from '@angular/core';

interface Testimonial {
  name: string;
  position: string;
  comment: string;
  rating: number;
  image: string;
}

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 4;
  testimonials: Testimonial[] = [
    {
      name: 'Budi Santoso',
      position: 'Direktur PT Maju Bersama',
      comment: 'Website company profile yang dibuat sangat profesional dan sesuai dengan brand identity perusahaan kami. Fitur admin yang mudah digunakan membantu kami mengelola konten secara mandiri.',
      rating: 5,
      image: this.getDefaultAvatar('male')
    },
    {
      name: 'Sarah Wijaya',
      position: 'Owner Toko Online Berkah',
      comment: 'Sistem informasi yang dikembangkan sangat membantu efisiensi operasional toko online kami. Proses pembayaran dan inventori terintegrasi dengan baik. Support team sangat responsif.',
      rating: 5,
      image: this.getDefaultAvatar('female')
    },
    {
      name: 'Dr. Ahmad Hidayat',
      position: 'Kepala Sekolah SMA Cerdas',
      comment: 'Aplikasi manajemen sekolah yang dikembangkan sangat membantu administrasi akademik kami. Sistem yang user-friendly dan dapat diakses dari mana saja membuat pekerjaan lebih efisien.',
      rating: 4.5,
      image: this.getDefaultAvatar('male')
    },
    {
      name: 'Dewi Pratiwi',
      position: 'Owner UMKM Makmur Jaya',
      comment: 'Belakanglayar Solutions memahami kebutuhan bisnis UMKM dengan baik. Website toko online kami kini lebih profesional dan trafik meningkat berkat optimasi SEO yang dilakukan.',
      rating: 5,
      image: this.getDefaultAvatar('female')
    },
    {
      name: 'Hendro Wibowo',
      position: 'Manager IT PT Berkah Jaya',
      comment: 'Sistem ERP yang dikembangkan sangat membantu operasional perusahaan kami. Integrasi antar departemen menjadi lebih efisien dan pengambilan keputusan lebih cepat.',
      rating: 5,
      image: this.getDefaultAvatar('male')
    },
    {
      name: 'Rina Amelia',
      position: 'Owner PT Sukses Selalu',
      comment: 'Belakanglayar Solutions memberikan solusi yang tepat untuk kebutuhan digital kami. Tim yang profesional dan responsif.',
      rating: 5,
      image: this.getDefaultAvatar('female')
    },
    {
      name: 'Andi Setiawan',
      position: 'Owner PT Maju Jaya',
      comment: 'Kami sangat puas dengan hasil kerja Belakanglayar Solutions. Website kami kini lebih menarik dan mudah diakses.',
      rating: 4.5,
      image: this.getDefaultAvatar('male')
    },
    {
      name: 'Rudi Hartono',
      position: 'Manager PT Sejahtera',
      comment: 'Pelayanan yang diberikan sangat memuaskan. Tim Belakanglayar Solutions sangat profesional dan cepat tanggap.',
      rating: 5,
      image: this.getDefaultAvatar('male')
    },
    {
      name: 'Rina Kusuma',
      position: 'Owner Klinik Sehat',
      comment: 'Sistem manajemen klinik yang dikembangkan sangat membantu dalam pengelolaan pasien dan inventori obat. Pelayanan menjadi lebih cepat dan akurat.',
      rating: 5,
      image: this.getDefaultAvatar('female')
    },
    {
      name: 'Irfan Hakim',
      position: 'CEO PT Digital Mitra',
      comment: 'Kualitas pengerjaan website sangat baik, komunikasi lancar, dan hasil sesuai ekspektasi. Sangat recommended untuk jasa pembuatan website profesional.',
      rating: 5,
      image: this.getDefaultAvatar('male')
    },
    {
      name: 'Linda Wijaya',
      position: 'Owner Resto Bahari',
      comment: 'Aplikasi kasir dan inventori yang dibuat sangat membantu operasional restoran kami. Laporan penjualan menjadi lebih akurat dan real-time.',
      rating: 4.5,
      image: this.getDefaultAvatar('female')
    },
    {
      name: 'Hendra Kusuma',
      position: 'Direktur CV Teknik Jaya',
      comment: 'Website company profile kami mendapat banyak feedback positif dari klien. Desainnya modern dan profesional.',
      rating: 5,
      image: this.getDefaultAvatar('male')
    },
    {
      name: 'Siti Aminah',
      position: 'Owner Butik Fashion',
      comment: 'Toko online kami mengalami peningkatan penjualan signifikan setelah menggunakan website baru. Sistem pembayaran dan tracking order sangat memudahkan customer.',
      rating: 5,
      image: this.getDefaultAvatar('female')
    },
    {
      name: 'Bambang Hermawan',
      position: 'Direktur PT Konstruksi Andalan',
      comment: 'Sistem manajemen proyek yang dikembangkan sangat membantu monitoring progress pembangunan. Semua data tersentralisasi dengan baik.',
      rating: 4.5,
      image: this.getDefaultAvatar('male')
    },
    {
      name: 'Maya Sari',
      position: 'Owner Salon Cantik',
      comment: 'Aplikasi booking online yang dibuat sangat membantu manajemen appointment customer. Fitur reminder otomatis sangat berguna.',
      rating: 5,
      image: this.getDefaultAvatar('female')
    },
    {
      name: 'Dodi Pratama',
      position: 'Manager Hotel Indah',
      comment: 'Website booking hotel kami kini lebih modern dan user-friendly. Sistem reservasi terintegrasi sangat memudahkan operasional.',
      rating: 5,
      image: this.getDefaultAvatar('male')
    },
    {
      name: 'Anita Dewi',
      position: 'Owner Toko Bangunan Jaya',
      comment: 'Sistem inventori dan POS yang dibuat sangat membantu kontrol stok dan penjualan. Laporan keuangan menjadi lebih terstruktur.',
      rating: 5,
      image: this.getDefaultAvatar('female')
    },
    {
      name: 'Rizki Pratama',
      position: 'CEO Startup Digital',
      comment: 'Kerjasama yang sangat profesional. Tim developer sangat memahami kebutuhan startup kami. Hasil pengerjaan memuaskan.',
      rating: 5,
      image: this.getDefaultAvatar('male')
    },
    {
      name: 'Diana Putri',
      position: 'Owner Online Shop',
      comment: 'Website toko online kami kini lebih menarik dan mudah digunakan. Fitur keranjang belanja dan checkout sangat user-friendly.',
      rating: 4.5,
      image: this.getDefaultAvatar('female')
    },
    {
      name: 'Agus Santoso',
      position: 'Direktur PT Logistik Cepat',
      comment: 'Sistem tracking pengiriman yang dibuat sangat membantu monitoring status kiriman. Customer sangat puas dengan transparansi informasi.',
      rating: 5,
      image: this.getDefaultAvatar('male')
    }
  ];

  constructor() { }

  ngOnInit(): void { }

  getDefaultAvatar(gender: 'male' | 'female'): string {
    return `assets/img/avatars/${gender}-avatar.png`;
  }

  getCurrentPageTestimonials(): Testimonial[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.testimonials.slice(startIndex, startIndex + this.itemsPerPage);
  }

  getTotalPages(): number {
    return Math.ceil(this.testimonials.length / this.itemsPerPage);
  }

  getPageNumbers(): number[] {
    return Array(this.getTotalPages()).fill(0).map((_, i) => i + 1);
  }

  changePage(page: number): void {
    if (page >= 1 && page <= this.getTotalPages()) {
      this.currentPage = page;
      window.scrollTo(0, 0);
    }
  }

  // Helper for star rating
  Math = Math; // Make Math available in template
}
