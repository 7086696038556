<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Terms & Conditions</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Terms & Conditions</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>
<div class="terms-conditions-area pt-50 pb-70">
    <div class="container">
        <div class="single-content">
            <h2>Syarat & Ketentuan Layanan Belakanglayar Solutions</h2>
            <p>Selamat datang di Belakanglayar Solutions. Dokumen ini menguraikan syarat dan ketentuan penggunaan layanan kami. Dengan menggunakan layanan kami, Anda menyetujui ketentuan-ketentuan berikut:</p>
            
            <h3>1. Layanan</h3>
            <p>Belakanglayar Solutions menyediakan layanan pengembangan website, sistem informasi, dan aplikasi digital. Setiap proyek akan didokumentasikan dalam kontrak terpisah yang mencakup lingkup pekerjaan, timeline, dan deliverables spesifik.</p>

            <h3>2. Hak dan Kewajiban Klien</h3>
            <ul>
                <li>Menyediakan informasi dan materi yang diperlukan untuk pengembangan proyek</li>
                <li>Melakukan pembayaran sesuai jadwal yang disepakati</li>
                <li>Memberikan feedback dalam waktu yang ditentukan</li>
                <li>Mematuhi ketentuan penggunaan dan lisensi software</li>
            </ul>

            <h3>3. Pembayaran</h3>
            <p>Pembayaran dilakukan dalam tiga tahap:</p>
            <ul>
                <li>50% di awal sebagai uang muka</li>
                <li>25% setelah desain disetujui</li>
                <li>25% setelah proyek selesai dan siap diluncurkan</li>
            </ul>

            <h3>4. Hak Kekayaan Intelektual</h3>
            <p>Setelah pelunasan pembayaran, klien mendapatkan hak penuh atas:</p>
            <ul>
                <li>Source code website/aplikasi</li>
                <li>Desain antarmuka</li>
                <li>Konten yang dibuat khusus untuk proyek</li>
                <li>Domain dan hosting (jika termasuk dalam paket)</li>
            </ul>

            <h3>5. Jaminan Layanan</h3>
            <p>Kami memberikan jaminan layanan meliputi:</p>
            <ul>
                <li>Garansi bug fixing selama 1 tahun</li>
                <li>Dukungan teknis 24/7</li>
                <li>Backup berkala</li>
                <li>Pemeliharaan sistem</li>
            </ul>

            <h3>6. Kerahasiaan</h3>
            <p>Kami berkomitmen menjaga kerahasiaan data dan informasi klien. Informasi proyek tidak akan dibagikan kepada pihak ketiga tanpa persetujuan tertulis.</p>

            <h3>7. Pembatalan dan Refund</h3>
            <p>Kebijakan pembatalan dan refund:</p>
            <ul>
                <li>Pembatalan sebelum pengerjaan: refund 90%</li>
                <li>Pembatalan saat proses desain: refund 50%</li>
                <li>Pembatalan setelah approval desain: tidak ada refund</li>
            </ul>

            <h3>8. Batasan Tanggung Jawab</h3>
            <p>Belakanglayar Solutions tidak bertanggung jawab atas:</p>
            <ul>
                <li>Kerugian akibat force majeure</li>
                <li>Penyalahgunaan website/aplikasi oleh klien</li>
                <li>Konten yang ditambahkan oleh klien</li>
                <li>Perubahan yang dilakukan tanpa persetujuan kami</li>
            </ul>
        </div>

        <div class="single-content">
            <h3>Hubungi Kami</h3>
            <p>Untuk pertanyaan tentang Syarat & Ketentuan ini, silakan hubungi kami melalui:</p>
            <ul>
                <li>Email: pipinibnu&#64;gmail.com</li>
                <li>Telepon: +62 8229-974-8978</li>
                <li>Alamat: Jl. Sukun No.22, Ngringin, Condongcatur, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta</li>
            </ul>
            <p>Terakhir diperbarui: Maret 2025</p>
        </div>
    </div>
</div>

<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>