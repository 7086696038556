<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Tentang Kami</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Tentang Kami</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="about-widget-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-widget-img">
                    <img src="assets/img/about/about-img4.png" alt="">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-widget-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">Tentang Kami</span>
                        <h2>Solusi Digital Terpercaya untuk Bisnis Anda</h2>
                    </div>
                    <h3>Tim Profesional dengan Pengalaman 5+ Tahun dalam Pengembangan Website dan Sistem Informasi di Yogyakarta</h3>
                    <p>Belakanglayar Solutions hadir sebagai partner terpercaya dalam mengembangkan presence digital bisnis Anda. Dengan kombinasi teknologi modern dan pemahaman mendalam tentang kebutuhan bisnis, kami siap membantu Anda bertransformasi di era digital.</p>
                    <div class="about-widget-list">
                        <ul>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>Website Profesional dengan Desain Modern dan SEO-Friendly untuk Meningkatkan Kredibilitas Bisnis Anda</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>Sistem Informasi Terintegrasi yang Dirancang Khusus Sesuai Kebutuhan dan Alur Kerja Perusahaan Anda</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>Dukungan Teknis Berkelanjutan dan Maintenance Regular untuk Memastikan Sistem Berjalan Optimal</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>5+ Tahun</h3>
                    <p>PENGALAMAN</p>
                </div>
            </div>
    
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>50+</h3>
                    <p>PROYEK SELESAI</p>
                </div>
            </div>
    
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>95%</h3>
                    <p>KLIEN PUAS</p>
                </div>
            </div>
    
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>10+</h3>
                    <p>EXPERT DEVELOPER</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top">
        <img src="assets/img/counter/counter-shape.png" alt="Images">
    </div>
</div>

<div class="service-list-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Nilai Kami</span>
            <h2>Komitmen untuk Kesuksesan Bisnis Anda</h2>
        </div>
    
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Misi Kami</h3>
                    <p>Memberikan solusi digital terbaik untuk membantu bisnis Anda berkembang di era digital dengan teknologi modern dan layanan profesional.</p>
                    <ul>
                        <li><i class='bx bx-check'></i> Inovasi Berkelanjutan</li>
                        <li><i class='bx bx-check'></i> Kualitas Terjamin</li>
                        <li><i class='bx bx-check'></i> Kepuasan Klien</li>
                        <li><i class='bx bx-check'></i> Solusi Tepat Guna</li>
                    </ul>
                </div>
            </div>
    
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Keunggulan Kami</h3>
                    <p>Tim profesional berpengalaman dalam pengembangan website dan sistem informasi dengan fokus pada kebutuhan spesifik klien.</p>
                    <ul>
                        <li><i class='bx bx-check'></i> Expert Developer</li>
                        <li><i class='bx bx-check'></i> Support 24/7</li>
                        <li><i class='bx bx-check'></i> Project Tepat Waktu</li>
                        <li><i class='bx bx-check'></i> Harga Kompetitif</li>
                    </ul>
                </div>
            </div>
    
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Jaminan Layanan</h3>
                    <p>Kami memberikan jaminan kualitas dan dukungan penuh untuk setiap proyek pengembangan website dan sistem informasi.</p>
                    <ul>
                        <li><i class='bx bx-check'></i> Garansi Maintenance</li>
                        <li><i class='bx bx-check'></i> Dokumentasi Lengkap</li>
                        <li><i class='bx bx-check'></i> Optimasi SEO</li>
                        <li><i class='bx bx-check'></i> Update Berkala</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Alur Kerja</span>
            <h2 class="h2-color2">Proses Pengembangan yang Terstruktur</h2>
        </div>
    
        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>Analisis Kebutuhan <span>1</span></h3>
                            <p>Mengidentifikasi kebutuhan bisnis Anda secara detail untuk memastikan solusi yang tepat sasaran</p>
                        </li>
                        <li class="text-end">
                            <h3>Perencanaan Proyek <span>2</span></h3>
                            <p>Menyusun rencana pengembangan, timeline, dan resource yang dibutuhkan untuk proyek Anda</p>
                        </li>
                        <li class="text-end">
                            <h3>Desain Interface <span>3</span></h3>
                            <p>Merancang tampilan yang menarik dan user-friendly sesuai dengan brand identity Anda</p>
                        </li>
                    </ul>
                </div>
            </div>
    
            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Proses Pengembangan Website dan Sistem Informasi">
                </div>
            </div>
    
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>4</span>Pengembangan</h3>
                            <p>Membangun sistem dengan teknologi modern dan kode yang terstruktur untuk performa optimal</p>
                        </li>
                        <li>
                            <h3><span>5</span>Pengujian</h3>
                            <p>Testing menyeluruh untuk memastikan sistem berjalan sesuai kebutuhan dan bebas bug</p>
                        </li>
                        <li>
                            <h3><span>6</span>Deploy & Support</h3>
                            <p>Implementasi sistem dan dukungan teknis berkelanjutan untuk operasional yang lancar</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="history-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Perjalanan Kami</span>
            <h2>Milestone Belakanglayar Solutions</h2>
        </div>
    
        <div class="history-list">
            <ul>
                <li class="active">
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2018</h3>
                        <span>Awal Perjalanan</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2019</h3>
                        <span>10+ Proyek Selesai</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2021</h3>
                        <span>25+ Klien Puas</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2023</h3>
                        <span>50+ Proyek Sukses</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2024</h3>
                        <span>Ekspansi Layanan</span>
                    </div>
                </li>
            </ul>
        </div>
    
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="history-content">
                    <h2>Perjalanan Menuju Kesuksesan</h2>
                    <p>Berawal dari tim developer berpengalaman di tahun 2018, Belakanglayar Solutions terus berkembang menjadi penyedia solusi digital terpercaya di Yogyakarta. Dengan komitmen pada kualitas dan inovasi, kami telah membantu puluhan bisnis bertransformasi di era digital.</p>
                    <p>Setiap tahun kami terus meningkatkan layanan dan mengadopsi teknologi terbaru untuk memberikan solusi terbaik bagi klien. Kepercayaan dan kepuasan klien menjadi bukti kesuksesan perjalanan kami.</p>
                    <a routerLink="/portfolio" class="default-btn">Lihat Portfolio <i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
    
            <div class="col-lg-6 col-md-12">
                <div class="history-img">
                    <img src="assets/img/history-img.png" alt="Perjalanan Belakanglayar Solutions - Jasa Pembuatan Website dan Sistem Informasi Yogyakarta">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand5.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>