<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Investasi Digital</h3>
            <ul>
                <li><a routerLink="/">Beranda</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Harga & Paket</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="pricing-area pt-50 pb-100">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span class="sp-after">Paket Layanan</span>
            <h2 class="h2-color">Solusi Digital Sesuai Kebutuhan Anda</h2>
            <p class="margin-auto">Harga menyesuaikan dengan kompleksitas dan fitur yang dibutuhkan. Konsultasi GRATIS!</p>
        </div>

        <div class="price-width">
            <div class="row">
                <!-- Website Package -->
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-banner pricing-icon color-1"></i>
                            <h3 class="color-1">Website Development</h3>
                            <div class="price-rate">
                                <h2 class="color-1">Mulai 500rb</h2>
                                <span class="color-1">Custom Development</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Company Profile</li>
                                <li><i class='bx bx-check'></i> E-Commerce</li>
                                <li><i class='bx bx-check'></i> Landing Page</li>
                                <li><i class='bx bx-check'></i> Website Portal</li>
                                <li><i class='bx bx-check'></i> SEO Friendly</li>
                            </ul>
                            <a routerLink="/contact" class="purchase-btn button-bg1">Konsultasi Gratis</a>
                        </div>
                    </div>
                </div>

                <!-- Sistem Informasi Package -->
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-mortgage-loan pricing-icon color-2"></i>
                            <h3 class="color-2">Sistem Informasi</h3>
                            <div class="price-rate">
                                <h2 class="color-2">Mulai 1 Juta</h2>
                                <span class="color-2">Custom Development</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> ERP System</li>
                                <li><i class='bx bx-check'></i> CRM System</li>
                                <li><i class='bx bx-check'></i> Inventory System</li>
                                <li><i class='bx bx-check'></i> HR Management</li>
                                <li><i class='bx bx-check'></i> Custom Module</li>
                            </ul>
                            <a routerLink="/contact" class="purchase-btn button-bg2">Konsultasi Gratis</a>
                        </div>
                    </div>
                </div>

                <!-- Mobile App Package -->
                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-processing pricing-icon color-3"></i>
                            <h3 class="color-3">Mobile App</h3>
                            <div class="price-rate">
                                <h2 class="color-3">Mulai 2 Juta</h2>
                                <span class="color-3">Custom Development</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Android App</li>
                                <li><i class='bx bx-check'></i> iOS App</li>
                                <li><i class='bx bx-check'></i> Cross Platform</li>
                                <li><i class='bx bx-check'></i> Backend System</li>
                                <li><i class='bx bx-check'></i> API Integration</li>
                            </ul>
                            <a routerLink="/contact" class="purchase-btn button-bg3">Konsultasi Gratis</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Additional Info Section -->
        <div class="row mt-5">
            <div class="col-12 text-center">
                <div class="pricing-note">
                    <h4>Mengapa Memilih Kami?</h4>
                    <ul class="list-unstyled">
                        <li><i class='bx bx-check'></i> Konsultasi GRATIS dan Tanpa Kewajiban</li>
                        <li><i class='bx bx-check'></i> Estimasi Harga Transparan</li>
                        <li><i class='bx bx-check'></i> Pembayaran Bertahap</li>
                        <li><i class='bx bx-check'></i> Garansi Support 1 Tahun</li>
                        <li><i class='bx bx-check'></i> Source Code Menjadi Hak Milik Client</li>
                    </ul>
                    <p class="mt-4">
                        <strong>* Harga dapat berbeda tergantung kompleksitas dan kebutuhan spesifik project</strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>