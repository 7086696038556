<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Testimoni Klien</h3>
            <ul>
                <li><a href="index.html">Beranda</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Testimoni</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>
<div class="testimonials-widget-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Testimoni Klien</span>
            <h2 class="h2-color2">Apa Kata Mereka Tentang Kami?</h2>
            <p>Testimoni dari klien yang telah mempercayakan pengembangan website dan sistem informasi kepada Belakanglayar Solutions</p>
        </div>

        <!-- Page 1 -->
        <div class="row pt-45">
            <div class="col-lg-6" *ngFor="let testimonial of getCurrentPageTestimonials()">
                <div class="testimonial-widget-item">
                    <div class="rating mb-2">
                        <!-- Dynamic star rating -->
                        <ng-container *ngFor="let star of [].constructor(5)">
                            <i class='bx' [ngClass]="{
                                'bxs-star': star < testimonial.rating,
                                'bxs-star-half': star === Math.floor(testimonial.rating) && testimonial.rating % 1 !== 0,
                                'bx-star': star >= testimonial.rating
                            }"></i>
                        </ng-container>
                    </div>
                    <p>{{testimonial.comment}}</p>
                    <div class="content">
                        <img [src]="testimonial.image" [alt]="'Testimoni ' + testimonial.name + ' - Klien Belakanglayar Solutions'">
                        <div class="content-title">
                            <h3>{{testimonial.name}}</h3>
                            <span>{{testimonial.position}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Pagination -->
        <div class="pagination-area text-center mt-4">
            <nav aria-label="Page navigation">
                <ul class="pagination justify-content-center">
                    <li class="page-item" [class.disabled]="currentPage === 1">
                        <a class="page-link" (click)="changePage(currentPage - 1)" aria-label="Previous">
                            <i class='bx bx-chevron-left'></i>
                        </a>
                    </li>
                    <li class="page-item" *ngFor="let page of getPageNumbers()" [class.active]="currentPage === page">
                        <a class="page-link" (click)="changePage(page)">{{page}}</a>
                    </li>
                    <li class="page-item" [class.disabled]="currentPage === getTotalPages()">
                        <a class="page-link" (click)="changePage(currentPage + 1)" aria-label="Next">
                            <i class='bx bx-chevron-right'></i>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<!-- Call to Action Section -->
<div class="cta-area bg-color1 pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <h2>Siap Mengembangkan Bisnis Anda?</h2>
                <p>Konsultasikan kebutuhan digital Anda dengan tim kami</p>
            </div>
            <div class="col-lg-5">
                <a routerLink="/contact" class="default-btn">Mulai Konsultasi Gratis <i class='bx bx-chevron-right'></i></a>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>